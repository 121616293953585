<template>

  <div class="page-content" >
    <div
        class="hp-top cover d-flex align-items-center relative"
        :style="`background-image: url('${homepage.featuredImage.node.sourceUrl}.webp')`"
    >
      <div class="container">
        <div class="mb-md-5 mb-4">
          <div class="hp-top__title">
            <span class="red-underline mb-3 font-hebo subtitle">{{ homepage?.template?.homepage?.title?.before }}</span>
            <h1>{{ homepage?.template?.homepage?.title?.text || '' }}</h1>
            <h2>
              {{ homepage?.template?.homepage?.title?.text2 || '' }}
              <span class="red-underline title-h4">{{ homepage?.template?.homepage?.title?.after }}</span>
            </h2>
          </div>
        </div>
        <VanSticky ref="filterSticky" z-index="150" :offset-top="70" class="car-filter-wrap">
          <div id="homeFilter" ref="stickyRef">
            <div class="container">
              <div class="car-filter car-filter-sticky" style="">
                <a href="javascript:void(0)" class="close-sticky-filter no_mobile" @click="closeSticky">
                  <nuxt-img src="/images/svg/close.svg" alt="אייקון המסמל כפתור לסגירה"/>
                </a>
                <div class="car-filter__title">נשמח להכיר, איזה רכב יש לך?</div>
                <div class="car-filter__inner">
                  <CarFilter />
                  <a class="bottom_link" href="javascript:void(0);" @click="openPopup">
                    &gt;&gt; אצטרך עזרה במציאת הרכב שלי
                  </a>
                </div>
              </div>
            </div>
          </div>
        </VanSticky>

      </div>
    </div>
    <LazyHomePromotion></LazyHomePromotion>
    <LazyHomeCategories></LazyHomeCategories>
    <LazyHomeWhyUs></LazyHomeWhyUs>
    <LazyHomeGallery></LazyHomeGallery>
    <LazyHomeAbout></LazyHomeAbout>
    <LazyHomeTestimonials></LazyHomeTestimonials>
    <LazyFooterContact></LazyFooterContact>
  </div>
</template>
<script setup lang="ts">

const {setCurrentPage} = useGlobalStore();
const {$activeModalsBus} = useNuxtApp();

const filterSticky = ref(null);
const stickyRef = ref(null);
const showStickyFilter = ref(true);

const homepage: Ref<object | null> = ref(null);
const loaded = ref(false);
const config = useRuntimeConfig();
// const {data, pending} = await useFetch(`/api/pages/home`);

// const data = await  GqlGetHomePage({uri: "/"});
const {data} = await useAsyncGql('getHomePage', {uri: "/"});
homepage.value = data?.value?.nodeByUri || null;

if (homepage.value) {
  setCurrentPage(homepage.value);
}
loaded.value = true;
const seo = JSON.parse(homepage.value?.seo?.fullHead);
delete seo['schema'];
seo.canonical = seo.canonical.replace('admin.','');
seo.og_url = seo.og_url.replace('admin.','');
useSeoMeta(seo);

const closeSticky = () => {
  const element = document.querySelector(".car-filter-wrap .van-sticky");
  element.classList.remove("van-sticky--fixed");

};



function openPopup() {
  $activeModalsBus.event("openPopup");
}

</script>
<style lang="scss">
#sticky-wrapper {
  width: 100% !important;
  left: 0 !important;
  z-index: 100;
}

.van-sticky {
  &--fixed {
    max-height: 70px;
  }
}

.car-filter-wrap {
  &.is-sticky {
    max-height: 60px;

    .container {
      max-width: 100% !important;
      padding: 0;
    }
  }
}
</style>
